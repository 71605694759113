import { Autocomplete, Avatar, Box, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Title } from "chart.js";
import View from "layouts/events/View";
import DataTable from "GoComponents/Events";

function TopReferrals() {
  const [top, setTop] = useState(null);
  const [date, setDate] = useState(null);
  const [sessionAttended, setSessionAttended] = useState(null);
  const [referralData, setReferralData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function convertData(data) {
    return data.map((item) => {
      const [number, referrals] = Object.entries(item)[0]; // Destructure the first entry of the object
      return {
        number: number,
        referrals: referrals,
      };
    });
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!top || !date) {
      setError(true);
      return;
    }
    try {
      setError(false);
      setLoading(true);
      const response = await axios.post(
        `${SERVER_URL}/user/topReferrals`,
        {
          top: top,
          time: date,
          hasAttendedSession: sessionAttended,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      const convertedData = convertData(data.referrals);
      setReferralData(convertedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const columns = [
    { Header: "Number", accessor: "number", align: "left" },
    { Header: "Referrals", accessor: "referrals", align: "left" },
  ];

  const rows =
    referralData &&
    referralData.map((d) => ({
      number: d.number,
      referrals: d.referrals,
    }));

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Top"
                  fullWidth
                  value={top}
                  onChange={(e) => setTop(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="datetime-local"
                  fullWidth
                  onChange={(e) => {
                    const fullDate = new Date(e.target.value);
                    const unixTime = fullDate.getTime();
                    setDate(unixTime);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <Autocomplete
                  //   disableClearable
                  value={sessionAttended}
                  options={["True", "False", "None"]}
                  onChange={(event, newValue) => {
                    if (newValue == "None") {
                      setSessionAttended(null);
                      return;
                    }
                    newValue == "True" ? setSessionAttended(true) : setSessionAttended(false);
                  }}
                  size="medium"
                  renderInput={(params) => <MDInput {...params} />}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  View
                </MDButton>
              </MDBox>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {loading && (
                  <Oval
                    visible={true}
                    height="35"
                    width="35"
                    color="#2196F3"
                    secondaryColor="#2196F3"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </Box>
              <MDBox mt={3} mb={1} textAlign="center"></MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      {referralData && !error && (
        <DataTable
          table={{ columns, rows }}
          entriesPerPage={false}
          showTotalEntries={true}
          noEndBorder
          canSearch={false}
          pagination={false}
        />
      )}
      {error && (
        <Box>
          <Typography color={"red"}>
            Sorry, Either no referrals for this number or something went wrong
          </Typography>
        </Box>
      )}
    </>
  );
}

export default TopReferrals;
